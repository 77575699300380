import { useEffect } from "react";
import styles from "./leftSidebar.module.scss";
//import Link from "next/link";
import useOutsideClick from "@/hooks/useOutSideClick";
import { useRef } from "react";
import sendGAEvent from "@/utils/gaEvent";

// components/Sidebar.js
export default function Sidebar({
  header_menu = [],
  clickedMenuIndex = null,
  setClickedMenuIndex = null,  
  isRajasthan=false , isMadhya=false,
  isHR = false, isUP = false, isBH=false
}) {
  let clickedMenu = header_menu[clickedMenuIndex];

  const ref = useRef();
  useOutsideClick(ref, () => {
    if (setClickedMenuIndex) {
      setClickedMenuIndex(null);
    }
  });

  useEffect(() => {
    window.addEventListener("scroll", function () {
      var submenu = document.querySelector(".submenu");
      // console.log("first", submenu);
      if (window.scrollY > 20 && submenu && submenu.style) {
        submenu.style.top = "64px"; // Stick to the top after scrolling past 65px
      } else if (submenu && submenu?.style) {
        submenu.style.top = "unset"; // Maintain initial 65px from top before scrolling past 65px
      }
    });
  }, []);
  if (clickedMenu) {
    return (
      <aside
        ref={ref}
        className={`${styles.submenu} submenu hidden md:flex flex-col overflow-scroll h-full py-1 border-r-[1px] border-gray-200`}
      >
        {clickedMenu?.sub_menu?.length > 0 && (
          <div
            className="bg-white"
            onMouseLeave={() => {
              setClickedMenuIndex(null);
            }}
          >
            <ul className="py-1 text-gray-700">
              {clickedMenu?.sub_menu?.map((sub_item, index2) => (
                <li
                  key={index2}
                  role="button"
                  className={`${isRajasthan ? 'hover:bg-rajasthanLight hover:text-rajasthanDark':isMadhya ? 'hover:bg-mpLight hover:text-mpDark':isHR ? 'hover:bg-hrLight hover:text-hrDark':isBH ? 'hover:bg-biharLight hover:text-biharDark':isUP ? 'hover:bg-upLight hover:text-upDark':'hover:bg-primary-300 hover:text-primary'} text-slate-600`}
                  onClick={() => {
                    let eventName = `Category List Click - ${clickedMenu?.title} - ${sub_item?.title} Tab`;

                    sendGAEvent({
                      eventName: eventName,
                      pageTitle: document?.title,
                      pageLocation: window?.location?.href,
                    });
                    window.location.href = `${sub_item?.canonical_url}`;
                  }}
                >
                  <a
                    href={sub_item?.canonical_url ?? "/"}
                    className="inline-block py-2 mx-2 "
                  >
                    <span className="px-2 font-bold text-2xl">·</span>
                    {sub_item?.title}
                  </a>
                  <hr />
                </li>
              ))}
            </ul>
          </div>
        )}
      </aside>
    );
  } else {
    return <></>;
  }
}
